@import "~quill/dist/quill.snow.css";
@import './assets/fonts/google.css';
// Define font sizes
$font-sizes: (
  12pt: 12pt,
  14pt: 14pt,
  16pt: 16pt,
  18pt: 18pt,
  19pt: 19pt,
  20pt: 20pt,
  21pt: 21pt,
  22pt: 22pt,
  24pt: 24pt,
  26pt: 26pt,
  28pt: 28pt,
  30pt: 30pt,
  32pt: 32pt,
  34pt: 34pt,
  36pt: 36pt,
  38pt: 38pt,
  40pt: 40pt
);

// Define font families
$font-families: (
  mirza: 'Mirza', 
  roboto: 'Roboto', 
  aref: 'Aref Ruqaa', 
  serif: serif, 
  sansserif: sans-serif, 
  monospace: monospace, 
  arial: 'Arial', 
  times-new-roman: 'Times New Roman'
);

// Generate font-size classes
@each $key, $size in $font-sizes {
  .ql-size-#{$key} {
    font-size: $size;
  }
}

// Generate font-family classes
@each $key, $font in $font-families {
  .ql-font-#{$key} {
    font-family: #{$font};
  }
}

// Quill Editor Customization
.ql-snow {
  .ql-picker {
    &.ql-size,
    &.ql-font {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

// Default Font Settings for Editor
.ql-editor {
  font-family: 'arial', sans-serif; // Default font family
  font-size: 16pt; // Default font size
  min-height: 145pt;
}

// Highlight Default Font Family in Picker
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  //font-weight: bold; // Optional: Make the default font stand out
  content: 'arial'; // Ensure 'Arial' is displayed
}

// Highlight Default Font Size in Picker
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16pt"]::before {
  content: '16pt'; // Ensure '20pt' is displayed
  //font-weight: bold; // Optional: Make the default size stand out
}
