// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Custom variable
@custom-primary-color: blue;

// Override less variables here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@font-family: "Poppins";
@select-item-active-bg: #EEEEEE;
@select-item-selected-bg: #EEEEEE;
@input-hover-border-color: #EEEEEE;
