@import "./quill.scss";

// Global styles.scss
// Example of other elements you might want to style globally
body {
  font-family: Arial, sans-serif; // Example font family
  line-height: 1.6; // Example line height
}

// Additional global styles as needed

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

mark {
  background-color: transparent !important;
  color: var(--primaryColor) !important;
}

html {
  font-family:
    Arial,
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mse-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-wrap {
  text-wrap: wrap;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.color-red {
  color: red;
}

.color-red-start {
  color: red;
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 40px;
}

.color-subtle {
  color: #00000070 !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.ant-btn-primary {
  color: var(--mainButtonTextColor);
  background-color: var(--primaryColor);
  border-radius: 4px;
  border-color: transparent;
}

.ant-btn:hover {
  background-color: var(--primaryColor);
  color: var(--secondaryTextColor);
}

.ant-btn-primary:hover {
  color: var(--secondaryTextColor);
  background-color: var(--thirdColor);
  border-color: var(--thirdColor);
}

.ant-btn-primary:focus {
  background-color: var(--primaryColor);
  color: var(--secondaryTextColor);
}

.font-weight-400 {
  font-weight: 400;
}

.headings {
  width: 100%;
  margin: 0px auto;

  h1,
  p,
  span {
    margin: 0;
  }

  span {
    font-size: 15px;
    font-weight: normal;
  }

  .font-size-20 {
    font-size: 20px;
  }

  .italic {
    font-style: italic;
  }
}

.card-button {
  width: 100%;
  max-width: 400px;
  min-height: 250px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid black;
  //margin: 10px auto;
  white-space: normal;
  background-color: var(--secondaryColor);
  color: var(--primaryTextColor); /* Corrected property name */
  transition: background-color 0.3s; /* Add a smooth transition effect */
}

.card-button:hover {
  background-color: var(
    --primaryColor
  ); /* Change the background color on hover */
}

.ant-switch {
  background-color: var(
    --primaryColor
  ); /* Change the background color on hover */
}

.disabled,
.disabled:hover {
  background: var(--thirdColor);
  /* Other styles for disabled state */
}

.categories-wrapper {
  width: 100%;
  margin: auto 0px !important;
}

a.ant-btn-lg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.saveExitBtn {
  margin-left: auto;
}

.ant-modal {
  margin: 0px;
  width: 75%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.ant-modal-body,
.ant-modal-footer {
  background: var(--thirdColor);
}

.ant-modal-footer {
  background: var(--thirdColor);
  text-align: left;
}

.ant-modal .ant-btn {
  background: var(--primaryColor);
  border: transparent;
}

/*
.ant-modal .ant-btn:hover{
  color: var(--secondaryTextColor);
  background-color: var(--thirdColor);
  border-color: var(--thirdColor);
}
*/
.ant-modal-header {
  background: var(--secondaryColor);
  border-bottom: 3px solid var(--primaryColor);
  height: 65px;
}

.ant-modal-title,
.ant-modal-close-x {
  font-weight: bold;
  font-size: 20px;
  color: var(--primaryTextColor);
  border: transparent;
}

.video-js {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0px;
  left: 0px;
}

.video-player-parent {
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.height-adjusted-video {
  height: 90% !important;
}

.full-height-video {
  height: 100% !important;
}

// Define variables for font sizes (optional)
$font-size-h1: 32px;
$font-size-h2: 30px;
$font-size-h3: 28px;
$font-size-h4: 20px;
$font-size-h5: 16px;
$font-size-h6: 14px;
$font-size-paragraph: 20px; // Adjust as needed

// Apply styles globally

// Headings
h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

// Visibility
.hide {
  display: none;
  visibility: hidden;
}

// Radio Button
.radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.radio-group input[type="radio"] {
  width: 16px;
  height: 16px;
  accent-color: var(--primaryColor);
  cursor: pointer;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

// Paragraphs
p, label, textarea {
  font-size: $font-size-paragraph;
}

@media (max-width: 767px) {
  // .ant-modal {
  //   margin-top: 228px !important;
  // }
}


.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}

@media only screen and (max-width: 768px) {
  .ant-modal {
    width: 80% !important;
  }

  .ant-modal-body{
    padding: 12px;
  }

  .ant-modal-header{
    height: 55px;
    padding: 12px 18px;
  }
}

// Scroll Bar Styling
*::-webkit-scrollbar {
  width: 0.6em;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}